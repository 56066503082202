import React, { useEffect } from 'react';
import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetServerSidePropsContext } from 'next';
import { useTranslation } from 'next-i18next';
import { API, APIRoutes, BANNER_TARGETS } from '@utils/api';
import MainBanner from '@components/PageBanner';
import Offers from '@components/Offers';
import News from '@components/News';
import Employers from '@components/EmployersCarousel';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import routes from '@utils/routes';

type HomepageProps = {
  banners: BannerItem[];
  internships: {
    offers: OfferBox[];
    meta: Meta;
  };
  offers: {
    offers: OfferBox[];
    meta: Meta;
  };
  news: NewsItem[];
  employers: Employer[];
};

function IndexPage({ banners, offers, internships, news, employers }: HomepageProps) {
  const { t } = useTranslation(['homepage', 'common']);
  const { query, replace } = useRouter();

  useEffect(() => {
    if (query.confirmed === 'false') {
      toast.error(t("Account hasn't been confirmed", { ns: 'common' }));
      replace(routes.homepage, undefined, { shallow: true });
    }
  }, [query, replace, t]);

  return (
    <>
      <Head>
        <title>{t('page-title')}</title>
        <meta property="og:image" content={`${process.env.NEXT_PUBLIC_PORTAL_URL}/images/lodz.png`} />
        <meta property="og:image:secure_url" content={`${process.env.NEXT_PUBLIC_PORTAL_URL}/images/lodz.png`} />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:height" content="279" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Apprenticeship and internship portal" />
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_PORTAL_URL}`} />
      </Head>
      <MainBanner bannerItems={banners?.filter((b) => b.target === BANNER_TARGETS.HOMEPAGE)} />
      {/* <Offers offers={internships.offers} offersCount={internships.meta.pagination.totalOffers} /> */}
      {internships?.offers && internships.offers.length > 0 && (
        <>
          <Offers offersCount={offers.meta.pagination.totalOffers} internships={internships.offers} />
          <News news={news} />
          <Offers offers={offers.offers} offersCount={0} hideFilters />
        </>
      )}
      {(!internships?.offers || internships.offers.length === 0) && (
        <>
          <Offers
            offers={offers.offers}
            offersCount={offers.meta.pagination.totalOffers}
            internships={internships.offers}
          />
          <News news={news} />
        </>
      )}

      <MainBanner bannerItems={banners.filter((b) => b.target === BANNER_TARGETS.COVER)} />
      <Employers employers={employers} />
    </>
  );
}

export async function getServerSideProps({ locale }: GetServerSidePropsContext) {
  try {
    const {
      data: { banners },
    } = await API.get(APIRoutes.banners([BANNER_TARGETS.HOMEPAGE, BANNER_TARGETS.COVER]));
    const { data: offers } = await API.get(
      APIRoutes.offers({ pageSize: 6, promoted: true, practiceType: 'practice_internship' })
    );

    const { data: internships } = await API.get(
      APIRoutes.offers({ pageSize: 6, promoted: true, practiceType: 'holiday_internship' })
    );

    const {
      data: { news },
    } = await API.get(APIRoutes.news);
    const {
      data: { employerProfiles },
    } = await API.get(APIRoutes.employers({ pageSize: 10, promoted: true }));

    return {
      props: {
        ...(await serverSideTranslations(locale, [
          'common',
          'homepage',
          'navbar',
          'footer',
          'offers',
          'news',
          'employers',
          'newsletter',
          'autocomplete',
        ])),
        banners,
        offers,
        internships,
        news,
        employers: employerProfiles,
      },
    };
  } catch (error) {
    return { props: {} };
  }
}

export default IndexPage;
